import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import SocialLinks from 'components/SocialLinks'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const Container = styled.div`
  padding: 100px;
  padding-top: 8vh;

  @media (max-width: 700px) {
    padding: 30px;
  }
`

const Header = styled.h2`
  text-align: center;
`

const FieldContainer = styled.div`
  padding: 10px;
`

/***********************************************************
 * From: https://github.com/dwyl/learn-to-send-email-via-google-script-html-no-server/blob/e5e01a8e8adf024e6ee80d2f70f26db123d4bda0/form-submission-handler.js#L13
 */
// get all data in form and return object
function getFormData(form) {
  var elements = form.elements
  var honeypot

  var fields = Object.keys(elements)
    .filter(function(k) {
      if (elements[k].name === 'address') {
        honeypot = elements[k].value
        return false
      }
      return true
    })
    .map(function(k) {
      if (elements[k].name !== undefined) {
        return elements[k].name
        // special case for Edge's html collection
      } else if (elements[k].length > 0) {
        return elements[k].item(0).name
      }
      return {}
    })
    .filter(function(item, pos, self) {
      return self.indexOf(item) === pos && item
    })

  var formData = {}
  fields.forEach(function(name) {
    var element = elements[name]

    // singular form elements just have one value
    formData[name] = element.value

    // when our element has multiple items, get their values
    if (element.length) {
      var data = []
      for (var i = 0; i < element.length; i++) {
        var item = element.item(i)
        if (item.checked || item.selected) {
          data.push(item.value)
        }
      }
      formData[name] = data.join(', ')
    }
  })

  // add form-specific values into the data
  formData.formDataNameOrder = JSON.stringify(fields)
  formData.formGoogleSheetName = form.dataset.sheet || 'responses' // default sheet name
  formData.formGoogleSend = form.dataset.email || '' // no email by default

  console.log(formData)
  return { data: formData, honeypot }
}
/***********************************************************/

const Contact = props => {
  const [formSubmitted, setSubmitted] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)

    const form = e.target
    const formData = getFormData(form)
    if (formData.honeypot) return
    const encodedFormData = Object.keys(formData.data)
      .map(function(k) {
        return (
          encodeURIComponent(k) + '=' + encodeURIComponent(formData.data[k])
        )
      })
      .join('&')

    console.log('handleSubmit, form:', formData)

    form.reset()

    axios
      .post(
        form.action,
        encodedFormData,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        // formData.data,
        // { headers: { 'Content-Type': 'application/javascript' } }
      )
      .then(response => {
        console.log('form submitted')
      })
      .catch(err => console.error('Could not submit form:', err))
  }

  return (
    <Container>
      <div style={{ height: '20vh' }}>
        <Header>Get in touch</Header>
      </div>
      <p style={{ textAlign: 'center' }}>
        I would love to discuss how I could contribute to your next project.
        Drop me a message and I will get back to you as soon as possible.
      </p>
      <SocialLinks />
      <form
        className="gform"
        method="POST"
        action={`https://script.google.com/macros/s/AKfycbxBVzIMD_cDF3PWAe-i3qLQPD85svjDolpewC36UmxNJRyBhcDh/exec`}
        onSubmit={handleSubmit}
      >
        <FieldContainer>
          <TextField name="name" label="Name" type="text" fullWidth required />
        </FieldContainer>
        <FieldContainer>
          <TextField
            name="email"
            label="Email"
            type="email"
            fullWidth
            required
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            style={{ marginTop: 20 }}
            name="message"
            label="Message"
            type="text"
            multiline
            minRows="5"
            maxRows="5"
            fullWidth
            required
            variant="outlined"
          />
        </FieldContainer>
        <input
          style={{ display: 'none' }}
          id="address"
          type="text"
          name="address"
        />
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="outlined"
            style={{
              marginTop: 25,
            }}
            type="submit"
            disabled={formSubmitted}
          >
            {!formSubmitted ? 'Send' : 'Thanks!'}
          </Button>
        </div>
      </form>
    </Container>
  )
}

export default Contact

// https://script.google.com/macros/s/AKfycbxM3XZ9YpPliRIhbPMVNj8mcEpj_KFi2LVaMQ6I2Q/exec
