import React from 'react'
import Link from '@material-ui/core/Link'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

const socialLinks = [
  {
    name: 'GitHub',
    icon: <GitHubIcon />,
    link: 'https://github.com/phonofidelic',
  },
  {
    name: 'Linked In',
    icon: <LinkedInIcon />,
    link: 'https://www.linkedin.com/in/christopher-clemons-89182aba',
  },
]

export default function SocialLinks() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {socialLinks.map(social => (
        <Link
          key={social.link}
          href={social.link}
          target="blank"
          color="textPrimary"
        >
          <div style={{ margin: 10 }}>{social.icon}</div>
        </Link>
      ))}
    </div>
  )
}
